<template>
  <v-container fluid class="ma-0 pa-0">
    <v-divider></v-divider>
    <v-subheader class="black--text text-subtitle-2">
      <v-icon color="red" class="mr-2">mdi-alert-circle-outline</v-icon>
      Contract Item Issues
    </v-subheader>
    <v-data-table 
      :headers="headers" 
      :items="items"
      :height="tableSize"
      :loading="loading"
      fixed-header
      :items-per-page="-1"
      :footer-props="{'items-per-page-options': [-1]}"
      dense>

      <template v-slot:[`item.case_cost`]="{ item }">
        <span class="font-weight-bold" v-if="item && item.contract_case_cost">
          {{ item.case_cost }}
        </span>
      </template>

      <template v-slot:[`item.contract_case_cost`]="{ item }">
        <span v-if="item && item.contract_case_cost">
          <span :class="(item.contract_case_cost === item.case_cost) ? 'font-weight-bold green--text' : 'font-weight-bold error--text'">
            {{ item.contract_case_cost }}
          </span>
        </span>
      </template>

      <template v-slot:[`item.qty_per_case`]="{ item }">
        <span class="font-weight-bold" v-if="item && item.contract_case_pack">
          {{ item.qty_per_case }}
        </span>
      </template>

      <template v-slot:[`item.contract_case_pack`]="{ item }">
        <span v-if="item && item.contract_case_pack">
          <span :class="(item.contract_case_pack === item.qty_per_case) ? 'font-weight-bold green--text' : 'font-weight-bold error--text'">
            {{ item.contract_case_pack }}
          </span>
        </span>
      </template>

      <template v-slot:[`item.awg_amap`]="{ item }">
        <span class="font-weight-bold" v-if="item && item.awg_amap && item.contract_amap">
          {{ item.awg_amap }}
        </span>
      </template>

      <template v-slot:[`item.contract_amap`]="{ item }">
        <span v-if="item && item.awg_amap && item.contract_amap">
          <span :class="(item.contract_amap === item.awg_amap) ? 'font-weight-bold green--text' : 'font-weight-bold error--text'">
            {{ item.contract_amap }}
          </span>
        </span>
      </template>

      <template v-slot:[`item.awg_eba`]="{ item }">
        <span class="font-weight-bold" v-if="item && item.awg_eba && item.contract_eba">
          {{ item.awg_eba }}
        </span>
      </template>

      <template v-slot:[`item.contract_eba`]="{ item }">
        <span v-if="item && item.awg_eba && item.contract_eba">
          <span :class="(item.contract_eba === item.awg_eba) ? 'font-weight-bold green--text' : 'font-weight-bold error--text'">
            {{ item.contract_eba }}
          </span>
        </span>
      </template>

      <template v-slot:[`item.linked_group`]="{ item }">
        <span v-if="item && item.linked_group">
          <v-btn icon color="red" class="ma-auto" 
            @click.stop="showLinkedItems(item)">
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <ContractLinkedItems
      v-if="linkedItemsModal"
      :value.sync="linkedItemsModal"
      :item="selectedItem"
      :currentItems="items"
      :readOnly="true"
      @cancel="linkedItemsModal = false">
    </ContractLinkedItems>
  </v-container>
</template>
<script>
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import { displayAlert } from '@/mixins/alert'
import { utils } from '@/mixins/utils'
import { fullWidthTable } from '@/mixins/table'
const ContractLinkedItems = () => import('@/components/contracts/ContractLinkedItems.vue')
export default {
  data () {
    return {
      loading: false,
      nonTableHeight: 500,
      tableSize: '',
      items: [],
      selectedItem: null,
      linkedItemsModal: false,
      headerProps: {
        sortable: false, filterable: false, class: 'accent white--text'
      },
      formatFields: [
        'case_cost', 'contract_case_cost', 'contract_amap',
        'awg_amap', 'contract_eba', 'awg_eba'
      ]
    }
  },
  name: 'ContractExceptionItems',
  components: { ContractLinkedItems },
  mixins: [displayAlert, utils, fullWidthTable],
  props: {
    value: Boolean,
    selectedContract: Object
  },
  async created() {
    this.tableSize = window.innerHeight - this.nonTableHeight
    await this.getExceptionItems()
  },
  computed: {
    baseHeaders() {
      return [
        { text: 'Source', value: 'source_name' },
        { text: 'Item Id', value: 'item_id' },
        { text: 'UPC', value: 'upc' },
        { text: 'Description', value: 'description' },
        { text: 'Case Cost', value: 'case_cost', align: 'center' },
        { text: 'Contract Case Cost', value: 'contract_case_cost', align: 'center' },
        { text: 'Case Pack', value: 'qty_per_case', align: 'center' },
        { text: 'Contract Case Pack', value: 'contract_case_pack', align: 'center' },
        { text: 'AWG AMAP', value: 'awg_amap', align: 'center' },
        { text: 'Contract AMAP', value: 'contract_amap', align: 'center' },
        { text: 'AWG EBA', value: 'awg_eba', align: 'center' },
        { text: 'Contract EBA', value: 'contract_eba', align: 'center' },
        { text: 'Linked Items', value: 'linked_group', align: 'center' }
      ].filter(header => {
        return this.oneExists(header.value)
      })
    },
    headers() {
      return this.baseHeaders.flatMap(header => {
        return { ...this.headerProps, ...header }
      })
    }
  },
  methods: {
    async getExceptionItems() {
      this.loading = true
      let items = []
      try {
        const { data } = await ContractUpload.getExceptionItems(this.selectedContract.upload_id)
        if (data?.length > 0) {
          items = data.map(item => this.formatItem(item))
        }
        this.items = items
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    formatItem(item) {
      item.source_name = this.$store.getters.getSourceName(item.source)
      for (const field of this.formatFields) {
        let value = item[field]
        if ((field === 'awg_amap' && !item.contract_amap) || (field === 'awg_eba' && !item.contract_eba)) {
          value = null
          item[field] = value
        }
        if (value && value != 0) {
          item[field] = `$${this.$config.formatCurrency(value)}`
        }
      }
      return item
    },
    showLinkedItems(item) {
      this.selectedItem = item
      this.linkedItemsModal = true
    },
    oneExists(key) {
      return this.items.some(item => item && (item[key] != null))
    }
  }
}
</script>