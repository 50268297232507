var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-divider"),
      _c(
        "v-subheader",
        { staticClass: "black--text text-subtitle-2" },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { color: "red" } }, [
            _vm._v("mdi-alert-circle-outline")
          ]),
          _vm._v(" Contract Item Issues ")
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          height: _vm.tableSize,
          loading: _vm.loading,
          "fixed-header": "",
          "items-per-page": -1,
          "footer-props": { "items-per-page-options": [-1] },
          dense: ""
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.case_cost",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.contract_case_cost
                    ? _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(item.case_cost) + " ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.contract_case_cost",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.contract_case_cost
                    ? _c("span", [
                        _c(
                          "span",
                          {
                            class:
                              item.contract_case_cost === item.case_cost
                                ? "font-weight-bold green--text"
                                : "font-weight-bold error--text"
                          },
                          [_vm._v(" " + _vm._s(item.contract_case_cost) + " ")]
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.qty_per_case",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.contract_case_pack
                    ? _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(item.qty_per_case) + " ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.contract_case_pack",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.contract_case_pack
                    ? _c("span", [
                        _c(
                          "span",
                          {
                            class:
                              item.contract_case_pack === item.qty_per_case
                                ? "font-weight-bold green--text"
                                : "font-weight-bold error--text"
                          },
                          [_vm._v(" " + _vm._s(item.contract_case_pack) + " ")]
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.awg_amap",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.awg_amap && item.contract_amap
                    ? _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(item.awg_amap) + " ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.contract_amap",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.awg_amap && item.contract_amap
                    ? _c("span", [
                        _c(
                          "span",
                          {
                            class:
                              item.contract_amap === item.awg_amap
                                ? "font-weight-bold green--text"
                                : "font-weight-bold error--text"
                          },
                          [_vm._v(" " + _vm._s(item.contract_amap) + " ")]
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.awg_eba",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.awg_eba && item.contract_eba
                    ? _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(item.awg_eba) + " ")
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.contract_eba",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.awg_eba && item.contract_eba
                    ? _c("span", [
                        _c(
                          "span",
                          {
                            class:
                              item.contract_eba === item.awg_eba
                                ? "font-weight-bold green--text"
                                : "font-weight-bold error--text"
                          },
                          [_vm._v(" " + _vm._s(item.contract_eba) + " ")]
                        )
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "item.linked_group",
              fn: function(ref) {
                var item = ref.item
                return [
                  item && item.linked_group
                    ? _c(
                        "span",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-auto",
                              attrs: { icon: "", color: "red" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showLinkedItems(item)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-view-list")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm.linkedItemsModal
        ? _c("ContractLinkedItems", {
            attrs: {
              value: _vm.linkedItemsModal,
              item: _vm.selectedItem,
              currentItems: _vm.items,
              readOnly: true
            },
            on: {
              "update:value": function($event) {
                _vm.linkedItemsModal = $event
              },
              cancel: function($event) {
                _vm.linkedItemsModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }